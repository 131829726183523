import React, { useState } from "react";
import "./style.scss";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
// Components
import ModalHoc from "../../Modal/";
import Icon from "../../Icon";
import FormControl from "../../Form/FormControl";
import Spinner from "../../Spinner";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { kycUpdate } from "../../../redux/features/kycSlice";

const AccountInfo = () => {
  const { data: kycdata, status: kycStatus } = useSelector(
    (state) => state.kyc
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };
  const user = useSelector((state) => state.user);
  const { data: kycData } = useSelector((state) => state.kyc);
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = (walletAddress) => {
    const data = {
      wallet: walletAddress,
      document_file_back: kycData?.document_file_back,
      document_file_front: kycData?.document_file_front,
      user: user?.pk,
    };
    dispatch(kycUpdate(data));
    handleCloseModal();
  };

  const fields = [
    {
      name: "walletAddress",
      label: "Wallet address",
      control: "input",
      placeholder: "Your personal wallet address",
    },
  ];

  const initialValues = {
    walletAddress: kycdata?.wallet || "",
  };

  const validationSchema = Yup.object({});

  return (
    <div className="account-status" data-testid="account-status">
      {!kycStatus ? (
        <Spinner />
      ) : (
        <div className="account-status__wrapper">
          <div className="account-status__header">
            <span className="account-status__kycinfo">Account Status</span>
            <span
              className={`account-status___kycStatus ${
                kycdata?.verified_kyc == null
                  ? "not-submitted"
                  : kycdata?.verified_kyc === false
                  ? "pending"
                  : kycdata?.verified_kyc === true
                  ? "approved"
                  : ""
              }`}
            >
              KYC{" "}
              {kycdata?.verified_kyc == null
                ? "Not submited"
                : kycdata?.verified_kyc === false
                ? "Pending"
                : kycdata?.verified_kyc === true
                ? "Verified "
                : ""}
            </span>
          </div>
          <div className="account-status__buttonWrapper">
            {!kycdata?.verified_kyc ? (
              <span>
                Create <span className="account-status__currency">HYDRA</span>{" "}
                wallet:
              </span>
            ) : (
              <span className="account-status__currency">
                Your <strong>HYDRA</strong> wallet{" "}
              </span>
            )}
            <button
              className="account-status__button"
              onClick={() =>
                kycdata?.verified_kyc
                  ? handleShowModal()
                  : window.open("https://hydrachain.org/wallet/", "_blank")
              }
            >
              {kycdata?.verified_kyc ? (
                <span>Edit Wallet</span>
              ) : (
                <span>Create wallet</span>
              )}
            </button>
          </div>
        </div>
      )}

      <ModalHoc show={showModal} handleClose={handleCloseModal}>
        {!kycdata?.verified_kyc ? (
          <div className="account-status__notSubmit">
            <h1 className="account-status__notSubmitTitle">
              You will need to submit a KYC Apllication before buying tokens.{" "}
            </h1>
            <h3 className="account-status__notSubmitSubtitle">
              Please fill in the KYC application as accurately as possible so we
              can ensure compliance on our part.
            </h3>
            <div className="form-control__btnSubmitWrapper">
              <Button
                className="form-control__submitBtn"
                onClick={() => navigate("/kyc")}
              >
                KYC Application
              </Button>
            </div>
          </div>
        ) : (
          <>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              className="form-control__formWrapper"
            >
              {(formik) => (
                <Form
                  className="form-control__form"
                  encType="multipart/form-data"
                >
                  {fields?.map((field, fieldIndex) => (
                    <FormControl
                      key={fieldIndex}
                      className="form-control__field"
                      control={field.control}
                      name={field.name}
                      label={field.label}
                      placeholder={field.placeholder}
                      options={field.options}
                      icon={field.icon}
                      value={formik.values[field.name] || ""}
                    />
                  ))}

                  <div className="form-control__btnSubmitWrapper">
                    <Button
                      className="form-control__submitBtn"
                      type="submit"
                      disabled={!formik.isValid}
                    >
                      Update Wallet!
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            <div className={`modal__note`}>
              <Icon
                name={"fa-exclamation-triangle"}
                className={`modal__icon`}
              />
              <span className="modal__text">
                DO NOT USE your exchange wallet address OR if you don't have a
                private key of the your address. You WILL NOT receive your token
                and WILL LOSE YOUR FUNDS if you do.
              </span>
            </div>
          </>
        )}
      </ModalHoc>
    </div>
  );
};

export default AccountInfo;
