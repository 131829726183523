// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px; }
`, "",{"version":3,"sources":["webpack://./src/components/Layout/style.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,eAAe,EAAA","sourcesContent":[".container {\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 15px;\n}\n.main-content-wrapper {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
