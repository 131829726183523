import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Components
import LoginForm from '../../components/Form/LoginForm';
import Spinner from '../../components/Spinner';

// Stykes & Icons
import '../../styles/pages/login.scss';

const LoginPage = () => {
  const navigate = useNavigate();
  const { status: authStatus } = useSelector((state) => state.auth);

  useEffect(() => {
    if (authStatus === 'succeeded') {
      navigate('/dashboard');
    }
  }, [authStatus, navigate]);



  return (
    <div className="login-page">
      <Container className="login-page__container">
        <Row>
          <Col>
            {authStatus === 'loading' ? (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Spinner animation="border" />
                <p>Logging in...</p>
              </div>
            ) : (
              <>
                <LoginForm />
                <div className="login-page__signin-linkWrapper">
                  Don't have an account?
                  <NavLink className="login-page__signin-link login-page-link" to="/register">
                    Sign up
                  </NavLink>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginPage;
