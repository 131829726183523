import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import "./style.scss"

library.add(fas);

const Icon = ({ name, className, ...rest }) => {
    return <FontAwesomeIcon icon={name} className={className} {...rest} />;
};

export default Icon;
