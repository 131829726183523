import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Homepage from './pages/homepage';
import Dashboard from './pages/dashboardpage';
import KYCPage from './pages/kycpage';
import BuyTokenPage from './pages/buytokenpage';
import TransactionPage from './pages/transactionpage';
import ProfilePage from './pages/profilepage';
import MyTokenPage from './pages/mytokenpage';

const Router = () => {
    const { accessToken } = useSelector((state) => state.auth);

    const isAuthenticated = () => accessToken !== null;

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/login" element={<Homepage />} />
                <Route path="/register" element={<Homepage />} />
                {isAuthenticated() && (
                    <>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/kyc" element={<KYCPage />} />
                        <Route path="/buytoken" element={<BuyTokenPage />} />
                        <Route path="/transactions" element={<TransactionPage />} />
                        <Route path="/profile" element={<ProfilePage />} />
                        <Route path="/mytoken" element={<MyTokenPage />} />
                    </>
                )}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;