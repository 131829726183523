import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userSlice from "./features/userSlice";
import authSlice from './features/authSlice';
import kycSlice from './features/kycSlice';
import transactionsSlice from './features/transactionsSlice';
import tokenSlice from './features/tokenSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'user', 'kyc', 'tokenStage', 'transactions'],
};

const rootReducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  kyc: kycSlice,
  tokenStage: tokenSlice,
  transactions: transactionsSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({serializableCheck: false,})],
  devTools:false
});

export default store;
