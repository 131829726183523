import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Row, Col } from "react-bootstrap";
import FormControl from "../../Form/FormControl";
import Icon from "../../Icon";
import "./styles.scss";
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import { updateUserPassword } from "../../../redux/features/userSlice";
import { useDispatch, useSelector } from "react-redux";

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: userData } = useSelector((state) => state.user);
  const [isNewPassowrd, setIsNewPassword] = useState(false);

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({});

  const onSubmit = (formData) => {
    const { newPassword, confirmPassword, oldPassword } = formData;
    const data = {
      user: userData.pk,
      old_password: oldPassword,
      password: newPassword,
      password2: confirmPassword,
    };
    dispatch(updateUserPassword(data))
      .then((response) => {
        if (response.payload.status === 200) {
          setIsNewPassword(true);
          navigate.push("/");
          toast.success('Password Updated Successfully');
        } else {
          setIsNewPassword(false);
        }
      })
      .catch((response) => {
        console.log(null);
      });
  };

  return (
    <div className="edit-password">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        className="form-control__formWrapper"
      >
        {(formik) => (
          <Form className="edit-password__form" encType="multipart/form-data">
            <Row style={{ display: "flex" }}>
              <Col md={6} className="personal-data__column">
                <FormControl
                  className="edit-password__field"
                  control="input"
                  type="password"
                  label="Old Password"
                  name="oldPassword"
                  value={formik.values.oldPassword}
                  placeholder="Old Password"
                  required
                />
                <FormControl
                  className="edit-password__field"
                  control="input"
                  type="password"
                  label="Confirm Password"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  placeholder="Confirm Password"
                  required
                />
              </Col>
              <Col md={6} className="personal-data__column">
                <FormControl
                  className="edit-password__field"
                  control="input"
                  type="password"
                  label="New Password"
                  name="newPassword"
                  value={formik.values.newPassword}
                  placeholder="New Password"
                  required
                />

                <Button
                  className="edit-password__submitBtn"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  Update Password
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      {isNewPassowrd ? (
        <p className="password__success">Password changed successfully.</p>
      ) : (
        <div className="personal-data__warning">
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Icon name="warning" className="personal-data__warning--icon" />
            <p style={{ margin: 0 }}>
              Password should be a minimum of 6 digits and include lower and
              uppercase letter.
            </p>
          </div>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Icon name="warning" className="personal-data__warning--icon" />
            <p style={{ margin: 0 }}>
              Your password will only change after your confirmation by email.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProfile;
