import React, { useEffect } from 'react';

// Components
import Layout from '../components/Layout';
import TransactionTable from '../components/Table';
import Spinner from '../components/Spinner';

// Styles
import '../styles/pages/transaction.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTransactions } from '../redux/features/transactionsSlice';

// Redux

const TransactionPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchTransactions());
    }, [dispatch])
    const { transactionslist: alltransactions, status: transactionsStatus } = useSelector((state) => state.transactions);

    return (
        <Layout>
            <div className="transaction-page">
                <div className="transaction-page__wrapper">
                    <div className="transaction-page__header">
                        <h4 className="card-title">Transactions list</h4>
                    </div>
                    {transactionsStatus !== 'succeeded' ? (<Spinner />) : (
                        <TransactionTable transactions={alltransactions} />
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default TransactionPage;