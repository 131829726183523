import React from 'react'

import InputField from './Fields/InputField';
import TextField from './Fields/TextField';
import SelectField from './Fields/SelectField';
import RadioField from './Fields/RadioField'
import CheckboxField from './Fields/CheckboxField';
import DatePickerField from './Fields/DatePIckerField';
import FileUploadField from './Fields/FileUploadField';

const FormControl = (props) => {
    const { control, ...rest } = props;

    switch (control) {
        case 'input':
            return <InputField {...rest} />
        case 'textarea':
            return <TextField {...rest} />
        case 'select':
            return <SelectField {...rest} />
        case 'radio':
            return <RadioField {...rest} />
        case 'checkbox':
            return <CheckboxField {...rest} />
        case 'date':
            return <DatePickerField {...rest} />
        case 'file':
            return <FileUploadField {...rest} />;

        default: return null
    }


    // return (
    //     <div>FormControl</div>
    // )
}

export default FormControl