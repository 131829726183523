import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoginPage from './auth/LoginPage';
import RegistrationPage from './auth/RegisterPage';
import background from '../assets/homepageBg.webp';

const Homepage = () => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const location = useLocation();

  return (
    <div className='homepage'>
      <div className='homepage__leftside'>
        <img src={background} alt="sugarverse" />
      </div>
      <div className='homepage__rightside'>
        {isLoggedIn ? (
          <Outlet /> // Render the nested routes if the user is logged in
        ) : location.pathname === '/register' ? (
          <RegistrationPage /> // Render the registration page if on '/register'
        ) : (
          <LoginPage /> // Render the login page by default
        )}
      </div>
    </div>
  );
};

export default Homepage;
