import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../api';

const initialState = {
    status: 'idle',
    data: {},
    error: null,
};

export const getStatus = createAsyncThunk('tokenStage/getStatus', async () => {
    try {
        const response = await instance.get('/salestat/');
        return response.data;
    } catch (error) {
        console.log(null);
    }
});

const tokenSlice = createSlice({
    name: 'tokenStage',
    initialState,
    reducers: {
        setWallet: (state, action) => {
            state.kycApplication.wallet = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStatus.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getStatus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
                state.data = action.payload
            })
            .addCase(getStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    },
});

export const { setWallet } = tokenSlice.actions;

export default tokenSlice.reducer;