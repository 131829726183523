import React from 'react';
import ReactDOM from 'react-dom/client';

// State Managment
import { Provider } from 'react-redux';
import store from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

// Notifications
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Router
import Router from './Router.jsx';

// Components
import Homepage from './pages/homepage';

// style
import './styles/main.scss';
export let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ToastContainer />
    <PersistGate loading={null} persistor={persistor} >
      <Provider store={store}>
        <Router>
          <Homepage />
        </Router>
      </Provider>
    </PersistGate>
  </React.StrictMode >
);
