import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router';

import countries from "../../../assets/jsons/countries.json";
import './style.scss';

import { useSelector } from 'react-redux';
import FormControl from '../../Form/FormControl';
import Icon from '../../Icon';

import { } from '../../../redux/features/userSlice';

const PersonalData = () => {
  const navigate = useNavigate();
  const { data: userData } = useSelector((state) => state.user);
  const { data: kycData } = useSelector((state) => state.kyc);

  const onSubmit = (data) => {

  };

  const initialValues = {
    name: kycData?.first_name ? `${kycData?.first_name} ${kycData?.last_name}` : '',
    email: kycData?.user_email || '',
    mobile: kycData?.mobile || '',
    date_of_birth: kycData?.date_of_birth || '',
    country: kycData?.nationality || '',
  };

  const validationSchema = Yup.object({
    country: Yup.string().required('Country is required'),
  });

  return (
    <div className="personal-data">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} className='personal-data__formWrapper'>
        {formik => (
          <Form className='personal-data__form' encType='multipart/form-data'>
            <Row>
              <Col md={6} className='personal-data__column'>
                <FormControl
                  className={'personal-data__field'}
                  control='input'
                  type='text'
                  label='Full Name'
                  name='full name'
                  value={formik.values.name}
                  placeholder='Full Name'
                  disabled

                />
                <FormControl
                  className={'personal-data__field'}
                  control='input'
                  type='phone'
                  label='Mobile Number'
                  name='mobile number'
                  value={formik.values.mobile}
                  placeholder='Mobile Number'
                  disabled

                />
                <FormControl
                  type="select"
                  control="select"
                  name="country"
                  label="Country"
                  options={countries}
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Select Country"
                  className="personal-data__field"
                  disabled
                />
              </Col>
              <Col md={6} className='personal-data__column'>
                <FormControl
                  className={'personal-data__field'}
                  control='input'
                  type='email'
                  label='Email Address'
                  name='email'
                  value={formik.values.email}
                  disabled

                />
                <FormControl
                  className={'personal-data__field'}
                  control='input'
                  type='text'
                  label='Date of Birth'
                  name='date of birth'
                  value={formik.values.date_of_birth} // Displayed from the initialValues
                  placeholder='Date of Birth'
                  disabled
                />
                <Button type='submit' className='personal-data__submitBtn' disabled>
                  Update Profile
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>

      {/* KYC warning */}
      <div className='personal-data__warning'>
        <p>
          <Icon name='warning' className='personal-data__warning--icon' />
          <span className='personal-data__warning--bold' style={{ marginLeft: '5px' }}>Note:</span> IF you need to Update your Profile please submit new KYC
        </p>
      </div>
    </div>
  );
};

export default PersonalData;
