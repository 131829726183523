import React from 'react';
import Icon from '../../Icon';
import { Field, ErrorMessage } from 'formik';

const TextField = (props) => {
  const { label, name, icon, ...rest } = props
  return (
    <div className='form-control__fieldWrapper'>
      {icon && <Icon name={icon} className='form-control__icon' />}
      <label className='form-control__label' htmlFor={name}>{label}</label>
      <Field as='textarea' id={name} name={name} {...rest} />
      <ErrorMessage
        name={name}
        component="div"
        className='form-control__error'
      />
    </div>
  )
}

export default TextField