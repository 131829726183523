// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transaction-page {
  margin-top: 1.25rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5);
  padding: 20px;
  margin-bottom: 2rem; }
  .transaction-page__wrapper {
    padding-top: 20px;
    margin-top: 20px; }
  .transaction-page__header {
    margin-bottom: 20px;
    color: purple; }
  .transaction-page .card-title {
    font-size: 24px;
    font-weight: bold;
    padding-left: 10px;
    margin-bottom: 20px; }

@media (max-width: 997px) {
  .transaction-page {
    width: 100%;
    font-size: 10px;
    padding: 0; }
    .transaction-page__wrapper {
      padding-top: 20px;
      margin-top: 20px; }
    .transaction-page__header {
      margin-bottom: 20px;
      color: purple; }
    .transaction-page .card-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px; } }
`, "",{"version":3,"sources":["webpack://./src/styles/pages/transaction.scss"],"names":[],"mappings":"AAAA;EACI,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;EACnB,2CAA2C;EAC3C,aAAa;EACb,mBAAmB,EAAA;EAEnB;IACI,iBAAiB;IACjB,gBAAgB,EAAA;EAGpB;IACI,mBAAmB;IACnB,aAAa,EAAA;EAfrB;IAmBQ,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB,EAAA;;AAI3B;EACI;IACI,WAAW;IACX,eAAe;IACf,UAAU,EAAA;IACV;MACI,iBAAiB;MACjB,gBAAgB,EAAA;IAGpB;MACI,mBAAmB;MACnB,aAAa,EAAA;IAXrB;MAeQ,eAAe;MACf,iBAAiB;MACjB,mBAAmB,EAAA,EACtB","sourcesContent":[".transaction-page {\n    margin-top: 1.25rem;\n    background-color: #fff;\n    border-radius: 10px;\n    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5);\n    padding: 20px;\n    margin-bottom: 2rem;\n\n    &__wrapper {\n        padding-top: 20px;\n        margin-top: 20px;\n    }\n\n    &__header {\n        margin-bottom: 20px;\n        color: purple;\n    }\n\n    .card-title {\n        font-size: 24px;\n        font-weight: bold;\n        padding-left: 10px;\n        margin-bottom: 20px;\n    }\n}\n\n@media (max-width: 997px) {\n    .transaction-page {\n        width: 100%;\n        font-size: 10px;\n        padding: 0;\n        &__wrapper {\n            padding-top: 20px;\n            margin-top: 20px;\n        }\n\n        &__header {\n            margin-bottom: 20px;\n            color: purple;\n        }\n\n        .card-title {\n            font-size: 24px;\n            font-weight: bold;\n            margin-bottom: 20px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
