import React from 'react';

// Styles & Images
import seedImg from '../../assets/images/Seed round.webp';
import meteorImg from '../../assets/images/Meteor 1.webp';
import './style.scss';

const Whitelist = () => {
  return (
    <>
      <div style={{ position: 'relative' }}>
        <img src={meteorImg} className="whitelist__meteor" alt="whitelist" />
      </div>
      <div className="whitelist" data-testid="whitelist">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="whitelist__imgWrapper">
            <img className="whitelist__image" src={seedImg} alt="seed round" />
          </div>
          <div className="whitelist__content">
            <h4 className="whitelist__title">Thank you for your interest to our Seed round</h4>
            <p className="whitelist__paragraph">To accquire CNDY token go to Buy Token page. </p>
            <p className="whitelist__paragraph">
              You can get a quick response to any questions, and chat with the project in our Telegram: https://t.me/Sugarverse_Official
            </p>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h3>Don’t hesitate to invite your friends!</h3>
          <button
            className="whitelist__button"
            onClick={() => window.open('https://sugarverse.github.io/', '_blank')}
          >
            Whitepapper
          </button>
        </div>
      </div>
    </>
  );
};

export default Whitelist;
