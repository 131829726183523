import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../api';
import { toast } from 'react-toastify';

const initialState = {
    status: 'idle',
    data: {
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        dateOfBirth: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        nationality: '',
        document_type: '',
        document_id: '',
        document_expiry_date: '',
        wallet_currency: '',
        wallet: '',
        document_file_front: '',
        document_file_back: '',
        verified_kyc: null
    },
    transactions: [],
    error: null,
};

export const handleKycApplication = createAsyncThunk('kyc/kycApplication', async (data, { rejectWithValue }) => {
    try {
        console.log(data, 'data');
        const response = await instance.post('/kyc/', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        return rejectWithValue(error.response.data);
    }
});



export const kycUpdate = createAsyncThunk('kyc/kycUpdate', async (data) => {
    try {
        const response = await instance.put('/kyc/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data;
    } catch (error) {
        console.log(null);
    }
});

export const getKyCStatus = createAsyncThunk('kyc/getKyCStatus', async () => {
    try {
        const response = await instance.get('/kyc/');
        return response.data;
    } catch (error) {
        console.log(null);
    }
});

const kycSlice = createSlice({
    name: 'kyc',
    initialState,
    reducers: {
        setWallet: (state, action) => {
            state.kycApplication.wallet = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(handleKycApplication.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(handleKycApplication.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
                state.data = action.payload;
            })
            .addCase(handleKycApplication.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getKyCStatus.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getKyCStatus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
                state.data = action.payload;
            })
            .addCase(getKyCStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(kycUpdate.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(kycUpdate.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
                state.data = action.payload;
            })
            .addCase(kycUpdate.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { setWallet } = kycSlice.actions;

export default kycSlice.reducer;
