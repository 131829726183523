import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Layout from "../components/Layout";
import ModalHoc from "../components/Modal";
import { handleTransactionBalance } from "../redux/features/transactionsSlice";

// Styles and icons
import "../styles/pages/mytoken.scss";

// Redux
import { useSelector, useDispatch } from "react-redux";

// Utils
import { useActiveStageData } from "../utils/hooks";

const MyTokenPage = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { data: tokenData } = useSelector((state) => state.transactions);
  const { data: tokenStageData } = useSelector((state) => state.tokenStage);
  const kycData = useSelector((state) => state.kyc.data);
  const activeStage = useActiveStageData(tokenStageData);

  useEffect(() => {
    dispatch(handleTransactionBalance());
  }, [dispatch]);
  
  return (
    <Layout>
      <div className="my-token-page">
        <div className="my-token-page__card-wrapper">
          <div className="my-token-page__card-head">
            <h4 className="my-token-page__card-title">My CNDY Token</h4>
          </div>

          <div className="my-token-page__card-content">
            <div className="my-token-page__token-balance">
              <div className="my-token-page__token-balance-info">
                <h6 className="my-token-page__token-balance-title">
                  Token Balance
                </h6>
                <span className="my-token-page__token-balance-amount">
                  {tokenData ? tokenData?.reserved : 0} CNDY
                </span>
                <p className="my-token-page__token-balance-note">
                  Equivalent to{" "}
                  <span>
                    {(
                      tokenData?.reserved * activeStage?.cndy_to_usd_price
                    ).toFixed(0)}{" "}
                    $
                  </span>
                </p>
              </div>
              {kycData?.verified_kyc ? (
                <NavLink
                  className="my-token-page__token-balance-action my-token-page__buy-button"
                  to="/buyToken"
                >
                  Buy Token
                </NavLink>
              ) : (
                <NavLink
                  className="my-token-page__token-balance-action my-token-page__buy-button"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Buy Token
                </NavLink>
              )}
              {showModal && (
                <ModalHoc
                  show={showModal}
                  handleClose={() => setShowModal(false)}
                  title="KYC application"
                >
                  <div className="account-status__notSubmit">
                    <h1 className="account-status__notSubmitTitle">
                      You will need to submit a KYC Apllication before buying
                      tokens.{" "}
                    </h1>
                    <h3 className="account-status__notSubmitSubtitle">
                      Please fill in the KYC application as accurately as
                      possible so we can ensure compliance on our part.
                    </h3>
                    <h3 className="account-status__notSubmitSubtitle">
                      We will need to verify it too, before we can enable you to
                      buy tokens.
                    </h3>
                    <div className="form-control__btnSubmitWrapper">
                      <NavLink
                        className="my-token-page__token-balance-action my-token-page__buy-button"
                        to="/kyc"
                      >
                        {kycData?.verified_kyc
                          ? "Buy Tokens"
                          : "KYC Application"}
                      </NavLink>
                    </div>
                  </div>
                </ModalHoc>
              )}
            </div>
          </div>

          <div className="my-token-page__card-separator"></div>

          <div className="my-token-page__card-content">
            <div className="my-token-page__total-token-amount">
              <div className="my-token-page__total-token-amount-info">
                <h5 className="my-token-page__total-token-amount-title">
                  Total Token Amount
                </h5>
                <span className="my-token-page__total-token-amount-value">
                  {tokenData?.reserved} CNDY
                </span>
              </div>
              <div className="my-token-page__token-details">
                <ul className="my-token-page__token-list">
                  <li>
                    <span className="my-token-page__token-title">
                      Purchased Token
                    </span>
                    <span className="my-token-page__token-amount">
                      {tokenData?.sold?.toFixed(0)} CNDY
                    </span>
                  </li>
                  <li>
                    <span className="my-token-page__token-title">
                      To Purchase Token
                    </span>
                    <span className="my-token-page__token-amount">
                      {(tokenData?.reserved - tokenData?.sold)?.toFixed(0)} CNDY
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="my-token-page__card-separator"></div>

          <div className="my-token-page__card-content">
            <div className="my-token-page__total-contributed">
              <div className="my-token-page__total-contributed-info">
                <h5 className="my-token-page__total-contributed-title">
                  Total Contributed
                </h5>
                <span className="my-token-page__total-contributed-amount">
                  {(tokenData?.sold * activeStage?.cndy_to_usd_price)?.toFixed(
                    0
                  )}{" "}
                  $
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MyTokenPage;
