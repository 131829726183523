import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Components
import { Button, Row, Col } from "react-bootstrap";
import Layout from "../components/Layout";
import Icon from "../components/Icon";
import ModalHoc from "../components/Modal";

// Styles and Icons
import hydraIcon from "../assets/coinIcons/hydra.webp";
import cndyIcon from "../assets/coinIcons/cndy.webp";
import "../styles/pages/buytoken.scss";

// Redux
import { handleReserveTokens } from "../redux/features/transactionsSlice";
import { useDispatch, useSelector } from "react-redux";

// Hooks
import { useActiveStageData } from "../utils/hooks";

const BuyTokenPage = () => {
  const { data: tokenData } = useSelector((state) => state.tokenStage);
  const [hydraAmount, setHydraAmount] = useState("");
  const [cndyAmount, setCndyAmount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const activeStageData = useActiveStageData(tokenData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleHydraInputChange = (event) => {
    const inputValue = event.target.value;
    setHydraAmount(inputValue);
    const calculatedCndy = inputValue * exchangeRate;
    setCndyAmount(calculatedCndy);
  };

  const exchangeRate = activeStageData?.hydra_to_cndy_price;

  const handleCndyInputChange = (event) => {
    const inputValue = event.target.value;
    setCndyAmount(inputValue);
    const calculatedHydra = inputValue / exchangeRate;
    setHydraAmount(calculatedHydra);
  };

  const handleBuyToken = () => {
    const data = { hydraAmount, cndyAmount };
    dispatch(handleReserveTokens(data))
      .then(() => {
        handleCloseModal();
        setHydraAmount("");
        setCndyAmount("");
        navigate("/transactions");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("Copied to clipboard!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  return (
    <Layout>
      <Row className="buy-token">
        <Col md={6} className="buy-token__left">
          <h1 className="buy-token__left-title">
            Use your HYDRA token to invest in CNDY
          </h1>
          <h3 className="buy-token__left-subtitle">
            Thorugh our partnership with HYDRA you can now swap your HYDRA token
            to CNDY at a preferential rate.
          </h3>
        </Col>
        <Col md={6} className="buy-token__right">
          <div className="buy-token__right-wrapper">
            <div style={{ width: "100%" }}>
              <div className="buy-token__exchangeBox">
                <div>
                  <h4>You pay</h4>
                  <input
                    type="number"
                    placeholder="0"
                    value={hydraAmount}
                    onChange={handleHydraInputChange}
                  />
                </div>
                <div className="buy-token__currencyWrapper">
                  <img src={hydraIcon} width="25px" height="25px" alt="HYDRA" />
                  HYDRA
                </div>
              </div>
              <div className="buy-token__iconWrapper">
                <Icon
                  name={"fa-solid fa-arrow-down"}
                  className={`buy-token__icon`}
                />
              </div>
              <div className="buy-token__exchangeBox">
                <div style={{ width: "100%" }}>
                  <h4>You receive</h4>
                  <input
                    type="number"
                    placeholder="0"
                    value={cndyAmount}
                    onChange={handleCndyInputChange}
                  />
                </div>
                <div className="buy-token__currencyWrapper">
                  <img src={cndyIcon} width="25px" height="25px" alt="CNDY" />
                  CNDY
                </div>
              </div>
            </div>
            <div className="buy-token__exchangeRate">
              1 HYDRA = {exchangeRate} CNDY
            </div>
            <button
              onClick={() => handleShowModal()}
              disabled={hydraAmount > 0 ? false : true}
            >
              BUY
            </button>
          </div>
        </Col>
      </Row>
      <ModalHoc show={showModal} handleClose={handleCloseModal}>
        <div className="buy-token__modal">
          <h1 className="buy-token__modalTitle">
            To receive your CNDY tokens, you must send
          </h1>
          <div className="buy-token__activeCurrencyAmout">
            {hydraAmount} HYDRA
          </div>
          <h3 className="buy-token__modalSubtitle">to wallet adress</h3>
          <div className="buy-token__walletAddress">
          HBZKjfg1X7VJiEiLTUdRAhRSRqBdXzZiBQ
            <Icon
              name={"fa-copy"}
              className={`buy-token__iconCopy`}
              onClick={() =>
                copyToClipBoard("HBZKjfg1X7VJiEiLTUdRAhRSRqBdXzZiBQ")
              }
            />
          </div>
          {copySuccess}
          <div className="buy-token__btnSubmitWrapper">
            <Button
              className="buy-token__submitBtn"
              onClick={() => handleBuyToken()}
            >
              Transaction Send
            </Button>
          </div>
          <div className={`buy-token__note`}>
            <Icon name={"fa-exclamation-triangle"} className={`modal__icon`} />
            <span className="modal__text">
              DO NOT USE your exchange wallet address OR if you don't have the
              private key of your address. You WILL NOT receive your token and
              WILL LOSE YOUR FUNDS if you do.
            </span>
          </div>
        </div>
      </ModalHoc>
    </Layout>
  );
};

export default BuyTokenPage;
