import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../api';

const initialState = {
    data: {
        email: '',
        pk: '',
    },
    status: 'idle',
    error: null,
};

export const fetchUser = createAsyncThunk('users/fetchUsers', async () => {
    try {
        const response = await instance.get('/user');
        return response.data;
    } catch (error) {
        console.log(null);
    }
});

export const updateUserPassword = createAsyncThunk('users/updateUserPassword', async (data) => {
    try {
        const response = await instance.put(`user/change/${data.user}/`, data);
        return response.data;
    } catch (error) {
        console.log(null);
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setWallet: (state, action) => {
            state.kycApplication.wallet = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateUserPassword.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateUserPassword.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(updateUserPassword.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });

    },
});

export const { setWallet } = userSlice.actions;

export const selectUser = (state) => state.user.user;
export const selectKycApplication = (state) => state.user.kycApplication;

export default userSlice.reducer;