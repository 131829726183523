import axios from 'axios';
import store from './store';
import { setAccessToken } from './features/authSlice';
import { toast } from 'react-toastify';

const url = 'https://ico-server-app-hbn94.ondigitalocean.app';

const instance = axios.create({
  baseURL: url,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

let lastErrorMessage = '';
let isRefreshing = false;
let refreshSubscribers = [];

instance.interceptors.request.use(
  (config) => {
    const accessToken = store.getState().auth.accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

async function refreshAccessToken() {
  if (!isRefreshing) {
    isRefreshing = true;
    try {
      const response = await axios.post(`${url}/api/token/refresh/`, {
        refresh: store.getState().auth.refreshToken,
      });
      store.dispatch(setAccessToken(response.data.access));
      refreshSubscribers.forEach((callback) => {
        callback(response.data.access);
      });
      refreshSubscribers = [];
    } catch (error) {
      const errorMessage = error.response.data.detail;
      localStorage.clear();
      window.location.pathname = '/login';
      toast.error(errorMessage);
      lastErrorMessage = errorMessage;
    } finally {
      isRefreshing = false;
    }
  }
}

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    console.log(null);
    if (error.response.status === 400) {
      toast.error(error.response.data.email);
      return Promise.reject(error);
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      const errorMessage = error.response.data.detail;
      if (errorMessage !== lastErrorMessage) {
        toast.error(errorMessage);
        lastErrorMessage = errorMessage;
      }
      // Queue the original request for later retry
      const retryOriginalRequest = new Promise((resolve) => {
        refreshSubscribers.push((newAccessToken) => {
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          resolve(instance(originalRequest));
        });
      });
      if (!isRefreshing) {
        refreshAccessToken();
      }
      return retryOriginalRequest;
    } else {
      const errorMessage = error.response.data.detail;
      toast.error(errorMessage);
      localStorage.clear();
      window.location.pathname = '/login';
      return Promise.reject(error);
    }
  }
);

export default instance;