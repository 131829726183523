import Spinner from 'react-bootstrap/Spinner';

function BasicExample() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner animation="border" role="status" style={{ color: '#ff2481' }}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
}

export default BasicExample;