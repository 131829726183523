import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
// Redux
import { useSelector } from 'react-redux';
// Components
import Spinner from '../../components/Spinner';
import RegistrationForm from '../../components/Form/RegistrationForm';
// Styles & Icons
import '../../styles/pages/registration.scss';

const RegistrationPage = () => {
  const navigate = useNavigate();
  const { status: authStatus } = useSelector((state) => state.auth);

  useEffect(() => {
    if (authStatus === 'succeeded') {
      navigate('/dashboard');
    }
  }, [authStatus, navigate]);

  return (
    <div className="registration-page">
      <Container className="registration-page__container">
        <Row>
          <Col>
            {authStatus === 'loading' ? (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Spinner animation="border" />
                <p>Registering...</p>
              </div>
            ) : (
              <>
                <RegistrationForm />
                <div className="registration-page__signin-linkWrapper">
                  Already have an account?
                  <NavLink className="registration-page__signin-link login-page-link" to="/login">
                    Sign in.
                  </NavLink>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default RegistrationPage;
