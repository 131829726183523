// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardPage {
  height: calc(100vh - 78px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px; }
  @media (max-width: 977px) {
    .dashboardPage {
      padding: 10px 0px;
      height: 100%; } }
`, "",{"version":3,"sources":["webpack://./src/styles/pages/dashboard.scss"],"names":[],"mappings":"AAEA;EACI,0BAA0B;EAC1B,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,SAAS,EAAA;EAET;IAPJ;MAQQ,iBAAiB;MACjB,YAAY,EAAA,EAEnB","sourcesContent":["@import \"../variables/variables\";\n\n.dashboardPage {\n    height: calc(100vh - 78px);\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    gap: 10px;\n\n    @media (max-width: 977px) {\n        padding: 10px 0px;\n        height: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
