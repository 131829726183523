import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../api';
import { toast } from 'react-toastify';

export const handleRegisterUser = createAsyncThunk('auth/register', async (userData, { rejectWithValue }) => {
  try {
    await instance.post('/user/create/', { email: userData.email, password: userData.password });
    const response = await instance.post('/api/token/', { email: userData.email, password: userData.password });
    return response.data;
  } catch (error) {
    toast.error(error.response.data);
    return rejectWithValue(error.response.data);
  }
});

export const handleLoginUser = createAsyncThunk('auth/login', async (userData, { rejectWithValue }) => {
  try {
    const response = await instance.post('/api/token/', { email: userData.email, password: userData.password });
    return response.data
  } catch (error) {
    toast.error(error.response.data);
    return rejectWithValue(error.response.data);
  }
});

const initialState = {
  accessToken: null,
  refreshToken: null,
  status: 'idle',
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
    },
    clearAccessToken: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    },
    setLogout(state) {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleRegisterUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(handleRegisterUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.accessToken = action.payload.access;
        state.refreshToken = action.payload.refresh;
      })
      .addCase(handleRegisterUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(handleLoginUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(handleLoginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.accessToken = action.payload.access;
        state.refreshToken = action.payload.refresh;
      })
      .addCase(handleLoginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
  },
});

export const { setAccessToken, clearAccessToken, setLogout } = authSlice.actions;

export default authSlice.reducer;