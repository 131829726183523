import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Components
import Layout from '../components/Layout';
import AccountInfo from '../components/TokenComponents/AccountInfo';
import PersonalData from '../components/ProfileComponents/PersonalData';
import EditProfile from '../components/ProfileComponents/EditProfile';
import ModalHoc from '../components/Modal';
import FormControl from '../components/Form/FormControl';
// Styles & Icons
import Icon from '../components/Icon';
import '../styles/pages/profile.scss';

// Redux
import { useSelector } from 'react-redux';

const ProfilePage = () => {
  const { data: kycData, status: kycStatus } = useSelector((state) => state.kyc);

  const [activeTab, setActiveTab] = useState('personal-data');
  const [showModal, setShowModal] = useState(false);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  }
  const validationSchema = Yup.object().shape({

  });
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const walletFields = [
    {
      name: 'walletAddress',
      label: 'Enter your wallet address',
      placeholder: 'Your personal wallet address',
      icon: 'fa-sticky-note',
      required: true,
      type: 'text',
    },
    {
      name: 'walletNote',
      label: 'Note',
      content: 'Address should be ERC20-compliant.',
      icon: 'fa-sticky-note',
      required: false,
      type: 'text',
    },
  ];

  const initialValues = {
    walletAddress: '',
    walletNote: '',
  };
  const handleSubmit = () => {
    handleCloseModal();
  };

  return (
    <Layout>
      <div className='profile-wrapper'>
        <Row >
          <Col md={8}>
            <div className="profile-page">
              <div className="profile-page__tabs">
                <ul className="profile-page__tabs-list">
                  <li
                    className={`profile-page__tabs-item ${activeTab === 'personal-data'
                      ? 'profile-page__tabs-item--active'
                      : ''
                      }`}
                  >
                    <a
                      href="#personal-data"
                      className="profile-page__tabs-link"
                      onClick={() => handleTabClick('personal-data')}
                    >
                      Personal Data
                    </a>
                  </li>
                  <li
                    className={`profile-page__tabs-item ${activeTab === 'edit-profile'
                      ? 'profile-page__tabs-item--active'
                      : ''
                      }`}
                  >
                    <a
                      href="#edit-profile"
                      className="profile-page__tabs-link"
                      onClick={() => handleTabClick('edit-profile')}
                    >
                      Edit Password
                    </a>
                  </li>
                </ul>
              </div>
              <div className="profile-page__content">
                {activeTab === 'personal-data' && <PersonalData />}
                {activeTab === 'edit-profile' && <EditProfile />}
              </div>
            </div>
          </Col>
          <Col md={4} >
            {!kycStatus && (
              <div className="profile-page__warning">
                <button>Add wallet address before buy</button>
                <Icon name="fa-arrow-right" />
              </div>
            )}
            <div className="profile-page__account-info">
              <AccountInfo showModal={handleShowModal} />
              <ModalHoc show={showModal} handleClose={handleCloseModal}>
                <div className='modal__note'>
                  <Icon name='fa-exclamation-triangle' className='modal__icon' />
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    className='form-control__formWrapper'
                  >
                    {(formik) => (
                      <Form className='form-control__form' encType='multipart/form-data'>
                        {walletFields?.map((field, index) => (
                          <FormControl
                            key={index}
                            className='form-control__field'
                            control={field.control}
                            name={field.name}
                            label={field.label}
                            placeholder={field.placeholder}
                            options={field.options}
                            icon={field.icon}
                            value={formik.values[field.name] || ''}
                          />
                        ))}
                        <div className='form-control__btnSubmitWrapper'>
                          <Button className='form-control__submitBtn' type='submit' disabled={!formik.isValid}>
                            Submit
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <span className='modal__text'>
                    DO NOT USE your exchange wallet address OR if you don't have a private key of your address. You WILL
                    NOT receive your token and WILL LOSE YOUR FUNDS if you do.
                  </span>
                </div>
              </ModalHoc>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default ProfilePage;
