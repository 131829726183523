import React from 'react';
import { Row, Col } from 'react-bootstrap';
import KycForm from '../components/Form/KycForm';
import Layout from '../components/Layout';
import '../styles/pages/kyc.scss';

const KYCPage = () => {
  return (
    <Layout>
      <div className='kyc-page'>
        <div className='kyc-page__header'>
          <h2>KYC Verification</h2>
          <h4>
            To comply with regulations, each participant is required to go through identity verification (KYC/AML) to prevent fraud, money laundering operations, transactions banned under the sanctions regime, or those which fund terrorism. Please complete our fast and secure verification process to participate in token offerings.
          </h4>
        </div>
        <Row>
          <Col>
            <KycForm />
          </Col>
        </Row>
      </div>
    </Layout>
  );

}

export default KYCPage;
