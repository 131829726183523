import React, { useState, useMemo, useCallback, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import moment from "moment";
import Spinner from "../../Spinner";
import { useSelector } from "react-redux";

import "./style.scss";
import meteorImg from "../../../assets/images/Meteor 1.webp";

// Hooks
import { useActiveStageData } from "../../../utils/hooks";

const TokenProgress = () => {
  const { data: tokenData } = useSelector((state) => state.tokenStage);
  const activeStageData = useActiveStageData(tokenData);

  const percent = useMemo(() => {
    if (activeStageData) {
      return Number(
        (
          (activeStageData.dolars_raised / activeStageData.dolars_total) *
          100
        ).toFixed(1)
      );
    }
    return 0;
  }, [activeStageData]);

  const [percentComplete, setPercentComplete] = useState(percent);
  const [countdown, setCountdown] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
  });

  const calculateCountdown = useCallback(() => {
    if (activeStageData) {
      const endDate = moment(activeStageData.sale_end);
      const currentTime = moment();
      const timeDifference = endDate.diff(currentTime);

      if (timeDifference > 0) {
        const duration = moment.duration(timeDifference);
        const days = Math.floor(duration.asDays()).toString().padStart(2, "0");
        const hours = duration.hours().toString().padStart(2, "0");
        const minutes = duration.minutes().toString().padStart(2, "0");

        setCountdown({ days, hours, minutes });
      }
    }
  }, [activeStageData]);

  useEffect(() => {
    if (tokenData && activeStageData) {
      setPercentComplete(percent);

      const interval = setInterval(calculateCountdown, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [calculateCountdown, activeStageData, tokenData, percent]);

  return (
    <div className="token-progress" data-testid="token-progress">
      <div className="token-progress__wrapper">
        {!activeStageData ? (
          <Spinner />
        ) : (
          <>
            <div className="token-progress__header">
              <div style={{ position: "relative" }}>
                <img
                  src={meteorImg}
                  className="token-progress__meteor"
                  alt="Token Sales Progress"
                />
              </div>
              <h2 className="token-progress__title">Token Sales Progress</h2>

              <h4 className="token-progress__subtitle">
                Round Availability :
                <span>
                  {" "}
                  {activeStageData?.total_tokens?.toLocaleString()} CNDY
                </span>
              </h4>
            </div>

            <div
              className="token-progress__barWrapper"
              style={{ position: "relative" }}
            >
              <ProgressBar
                now={percentComplete}
                label={`${percentComplete}%`}
              />
              <div
                className="token-progress__info"
                style={{ left: `calc(${percentComplete}% - 5%)` }}
              >
                <span className="token-progess-amount">
                  {activeStageData.dolars_raised.toFixed(0)} $
                </span>
                <span className="token-progess-amountDevider">/</span>
                <span className="token-progess-totalToken">
                  {activeStageData.dolars_total?.toLocaleString()} $
                </span>
              </div>
            </div>

            <div className="token-progress__countdown">
              <div className="token-progress__countdown-time">
                <span className="token-progress__countdown-time-value">
                  {countdown.days}
                </span>
                <span className="token-progress__countdown-time-text">
                  Day{countdown.days !== "01" ? "s" : ""}
                </span>
              </div>
              <div className="token-progress__countdown-time">
                <span className="token-progress__countdown-time-value">
                  {countdown.hours}
                </span>
                <span className="token-progress__countdown-time-text">
                  Hour
                </span>
              </div>
              <div className="token-progress__countdown-time">
                <span className="token-progress__countdown-time-value">
                  {countdown?.minutes}
                </span>
                <span className="token-progress__countdown-time-text">Min</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TokenProgress;
