import React from 'react'
import { handleRegisterUser } from '../../redux/features/authSlice';
import { Button } from 'react-bootstrap'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormControl from './FormControl';

import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
const RegistrationForm = () => {

  const dispatch = useDispatch();

  const initialValues = {
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    email: Yup.string().required('Email is required').email('Invalid email format'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const onSubmit = (values, onSubmitProps) => {
    dispatch(handleRegisterUser(values))
      .unwrap()
      .then(() => {
        toast.success('Registration Successful');
        onSubmitProps.resetForm();
      })
      .catch((rejectedValueOrSerializedError) => {
        if (rejectedValueOrSerializedError.hasOwnProperty('email')) {
          toast.error(rejectedValueOrSerializedError.email[0]);
        } else {
          toast.error(rejectedValueOrSerializedError.message);
        }
      })
      .finally(() => {
        onSubmitProps.setSubmitting(false);
      });
  }

  return (
    <div className='form-control'>
      <h1 className='form-control__title'>Sign Up</h1>

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} className='form-control__formWrapper'>
        {
          formik => (
            <Form className='form-control__form'>
              <FormControl className='form-control__field' control='input' type='text' label='Username' name='username' placeholder='Enter your name' />
              <FormControl className='form-control__field' control='input' type='email' label='Email' name='email' placeholder='Enter your email' />
              <FormControl className='form-control__field' control='input' type='password' label='Password' name='password' placeholder='Enter your password' />
              <FormControl className='form-control__field' control='input' type='password' label='Confirm Password' name='confirmPassword' placeholder='Confirm your password' />
              <div className='form-control__btnSubmitWrapper'>
                <Button className='form-control__submitBtn' type='submit' disabled={!formik.dirty || !!Object.keys(formik.errors).length}>
                  Sign Up
                </Button>
              </div>
            </Form>
          )
        }
      </Formik>
    </div>
  )
}

export default RegistrationForm