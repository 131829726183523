import React from 'react';
import Icon from '../../Icon';
import { Field, ErrorMessage } from 'formik';

const CheckboxField = (props) => {
  const { label, name, options,icon, ...rest } = props
  return (
    <div className='form-control__fieldWrapper'>
      {icon && <Icon name={icon} className='form-control__icon' />}
      <label className='form-control__label' htmlFor={name}>{label}</label>
      <Field name={name} {...rest} >
        {
          ({ field }) => {
            return options.map(option => {
              return (
                <React.Fragment key={option.key}>
                  <input
                  
                    type='checkbox'
                    id={option.value}
                    {...field}
                    value={option.value}
                    checked={field.value?.includes(option.value)}
                  />
                  <label htmlFor={option.value}>{option.key}</label>
                </React.Fragment>
              )
            })
          }
        }
      </Field>
      <ErrorMessage
        name={name}
        component="div"
        className='form-control__error'
      />
    </div>
  )
}

export default CheckboxField