import React from 'react';
import { Row, Col } from 'react-bootstrap';

// Components
import Layout from '../components/Layout';
import TokenBalance from '../components/TokenComponents/TokenBalance';
import TokenStage from '../components/TokenComponents/TokenStage';
import AccountInfo from '../components/TokenComponents/AccountInfo';
import TokenSalesProgress from '../components/TokenComponents/TokenSalesProgress';
import WhiteList from '../components/WhiteList';

// Styles & Icons
import '../styles/pages/dashboard.scss';

const Dashboard = () => {
  return (
    <Layout>
      <div className='dashboardPage'>
        <Row>
          <Col md={4}>
            <TokenBalance />
          </Col>
          <Col md={4}>
            <TokenStage />
          </Col>
          <Col md={4}>
            <AccountInfo />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <WhiteList />
          </Col>
          <Col md={6}>
            <TokenSalesProgress />
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Dashboard;
