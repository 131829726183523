import React from "react";
import Icon from "../../Icon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Field, ErrorMessage } from "formik";

const DatePickerField = (props) => {
  const { label, name, placeholder, icon, ...rest } = props;

  return (
    <div className="form-control__fieldWrapper">
      {icon && <Icon name={icon} className="form-control__icon" />}
      <label className="form-control__label" htmlFor={name}>
        {label}
      </label>
      <Field name={name}>
        {(fieldProps) => {
          const { form, field } = fieldProps;
          const { setFieldValue } = form;
          const { value } = field || {};
          return (
            <DatePicker
              id={name}
              {...field}
              {...rest}
              showYearDropdown
              showIcon={true}
              selected={value}
              placeholderText={placeholder}
              onChange={(val) => setFieldValue(name, val)}
            />
          );
        }}
      </Field>
      <ErrorMessage
        name={name}
        component="div"
        className="form-control__error"
      />
    </div>
  );
};

export default DatePickerField;
