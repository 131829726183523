import React, { useEffect } from 'react';

// Components
import Chart from '../../Chart';
import Spinner from '../../Spinner';

// State Managment
import { batch, useDispatch, useSelector } from 'react-redux';
import { getStatus } from '../../../redux/features/tokenSlice';
import { getKyCStatus } from '../../../redux/features/kycSlice';

// Styles
import './style.scss';

// Hooks
import { useActiveStageData } from '../../../utils/hooks';

const TokenStage = () => {
  const { data: tokenData} = useSelector(state => state.tokenStage);
  const dispatch = useDispatch();
  const activeStageData = useActiveStageData(tokenData);

  useEffect(() => {
    batch(() => {
    dispatch(getStatus());
    dispatch(getKyCStatus());
    });
  }, [dispatch]);

  return (
    <div className="stage" data-testid="stage">
      <div className="stage__wrapper">
        {!tokenData ? (
          <Spinner />
        ) : (
          <>
            <div className="stage__header">
              <span className="stage__exchangeRate">
                1 CNDY = $ {tokenData ? activeStageData?.cndy_to_usd_price : null}
              </span>
              <span className="stage__status">
                Live
              </span>
            </div>
            <div className="stage__body">
              <Chart tokenData={tokenData} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TokenStage;