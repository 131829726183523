import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { Dropdown, DropdownButton } from 'react-bootstrap';
import { setLogout } from '../../redux/features/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import './styles.scss';
import { toast } from 'react-toastify';
import ModalHoc from '../Modal';

const Navigation = () => {
  const [showNav, setShowNav] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const kycData = useSelector(state => state.kyc.data);
  const transactionsBalanceData = useSelector(state => state.transactions.data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const handleLogout = () => {
    dispatch(setLogout());
    navigate('/');
    toast.success('Logout Successful');
  };
  return (
    <nav className={`navigation ${showNav ? 'showHamburger' : ''}`}>
      <div className='navigation__wrapper'>
        <NavLink className='navigation__item' to='/dashboard'>
          Dashboard
        </NavLink>

        <NavLink className='navigation__item' to='/transactions'>
          Transactions
        </NavLink>

        <DropdownButton
          bsPrefix='navigation__dropdown'
          align='end'
          title='Profile'
          className='navigation__dropdownWrapper'
        >
          {kycData?.first_name && (
            <div className='navigation__userInfo'>
              <p>{kycData?.user_email || ''}</p>
              {transactionsBalanceData && (
                <div className='navigation__tokensInfo'>
                  {/* <p>Reserved: {transactionsBalanceData.reserved} CNDY</p> */}
                  <p>Own: {transactionsBalanceData.sold ? transactionsBalanceData.sold.toFixed(0) : 0} CNDY</p>
                </div>
              )}
            </div>
          )}
          <div style={{ backgroundColor: 'white', textAlign: 'center', cursor: 'pointer' }}>
            <Dropdown.Item eventKey='3' className='header__dropdown-item' onClick={() => navigate('/profile')}>
              Settings
            </Dropdown.Item>
            <Dropdown.Divider className='header__dropdown-divider' />
            <Dropdown.Item eventKey='4' className='header__dropdown-item' onClick={() => handleLogout()}>
              Logout
            </Dropdown.Item>
          </div>
        </DropdownButton>

        <NavLink className='navigation__item' to='/mytoken'>
          <span className='icon__menu' /> My Token
        </NavLink>

        <NavLink className='navigation__item navigation__kyc' to='/kyc'>
          KYC Application
        </NavLink>

        {kycData?.verified_kyc ? (
          <NavLink className='navigation__buyToken .active' to='/buyToken'>
            Buy Token
          </NavLink>
        ) : (
          <NavLink className='navigation__buyToken .active' onClick={() => { setShowModal(true) }}>
            Buy Token
          </NavLink>
        )}
        {showModal && (
          <ModalHoc show={showModal} handleClose={() => setShowModal(false)} title="KYC application">
            <div className='account-status__notSubmit'>
              <h1 className='account-status__notSubmitTitle'>You will need to submit a KYC Apllication before buying tokens. </h1>
              <h3 className='account-status__notSubmitSubtitle'>
                Please fill in the KYC application as accurately as possible so we can ensure compliance on our part.
              </h3>
              <h3 className='account-status__notSubmitSubtitle'>
                We will need to verify it too, before we can enable you to buy tokens.
              </h3>
              <div className='form-control__btnSubmitWrapper'>
                <button className="token-balance__button" onClick={() => { navigate('/kyc') }}>
                  {kycData?.verified_kyc ? 'Buy Tokens' : 'KYC Application'}
                </button>
              </div>
            </div>
          </ModalHoc>
        )}
      </div>
      <button className='navigation__hamburger' onClick={toggleNav}>
        <span className={`fas ${showNav ? 'fa-times' : 'fa-bars'}`} />
      </button>
    </nav>
  );
};

export default Navigation;