import React from 'react'
import Icon from '../../Icon';
import { Field, ErrorMessage } from 'formik'

const InputField = (props) => {
  const { label, name, icon, value, ...rest } = props
  return (
    <div className='form-control__fieldWrapper'>
      {icon && <Icon name={icon} className='form-control__icon' />}
      <label className='form-control__label' htmlFor={name}>{label}</label>
      <Field id={name} name={name} value={value}  {...rest} />
      <ErrorMessage
        name={name}
        as={< Icon name="fa-exclamation-triangle" className="form-control__errorIcon" />}
        className="form-control__error"
      />
    </div>
  )
}

export default InputField