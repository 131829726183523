import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../api";
import { toast } from "react-toastify";

const initialState = {
  status: "idle",
  data: {},
  transactionslist: [],
  error: null,
};

export const fetchTransactions = createAsyncThunk(
  "transactions/fetchTransactions",
  async () => {
    try {
      const response = await instance.get("/transaction/list/");
      return response.data;
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  }
);

export const handleReserveTokens = createAsyncThunk(
  "transactions/reserveTokens",
  async (data, { rejectWithValue }) => {
    try {
      const sendData = {
        transaction_currency: "hydra",
        transaction_amount_spent: data?.hydraAmount,
        transaction_amount_left: "0",
        ico_amount_sold: data?.cndyAmount,
        ico_amount_reserved: data?.cndyAmount,
        ico_amount_left: "0",
      };
      const response = await instance.post("/transaction/", sendData);
      return response.data;
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  }
);

export const handleTransactionBalance = createAsyncThunk(
  "transactions/ballance",
  async (data) => {
    try {
      const response = await instance.get("/transaction/balance/");
      return response.data;
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  }
);

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setWallet: (state, action) => {
      state.data.wallet = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.transactionslist = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(handleReserveTokens.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(handleReserveTokens.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(handleReserveTokens.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(handleTransactionBalance.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(handleTransactionBalance.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        state.data = action.payload;
      })
      .addCase(handleTransactionBalance.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setWallet } = transactionsSlice.actions;

export default transactionsSlice.reducer;
