import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import FormControl from './FormControl';
import countries from '../../assets/jsons/countries.json';
import idTypes from '../../assets/jsons/idTypes.json';
import './style.scss';
import { getKyCStatus, kycUpdate, handleKycApplication } from '../../redux/features/kycSlice';
import { fetchUser } from '../../redux/features/userSlice';

const sections = [
  {
    title: 'Personal Data',
    subtitle: 'Your basic personal information is required for identification purposes.',
    fields: [
      {
        name: 'first_name',
        label: 'First Name',
        control: 'input',
        placeholder: 'Enter your first name',
      },
      {
        name: 'last_name',
        label: 'Last Name',
        control: 'input',
        placeholder: 'Enter your last name',
      },
      {
        name: 'email',
        label: 'Email',
        control: 'input',
        placeholder: 'Enter your email',
      },
      {
        name: 'mobile',
        label: 'Mobile Number',
        control: 'input',
        placeholder: 'Enter Mobile Number',
      },
      {
        name: 'dateOfBirth',
        label: 'Date of Birth',
        control: 'date',
        placeholder: 'Enter your date of birth',
      },
      {
        name: 'address',
        label: 'Address',
        control: 'input',
        placeholder: 'Enter your address',
      },
      {
        name: 'city',
        label: 'City',
        control: 'input',
        placeholder: 'Enter your city',
      },
      {
        name: 'state',
        label: 'State',
        control: 'input',
        placeholder: 'Enter your state',
      },
      {
        name: 'zip_code',
        label: 'Zip Code',
        control: 'input',
        placeholder: 'Enter your zip code',
      },
      {
        name: 'nationality',
        label: 'Nationality',
        control: 'select',
        options: [
          ...countries,
        ],
      },
    ],
  },
  {
    title: 'Document Upload',
    subtitle: 'To verify your identity, we ask you to upload high-quality scans or photos of your official identification documents issued by the government.',
    fields: [
      {
        name: 'idType',
        label: 'Type of ID',
        control: 'select',
        options: idTypes,
      },
      {
        name: 'idNumber',
        label: 'ID Number',
        control: 'input',
        placeholder: 'Enter your ID number',
      },
      {
        name: 'idExpiryDate',
        label: 'ID Expiry Date',
        control: 'date',
        placeholder: 'Enter your ID expiry date',
      },
      {
        name: 'idFront',
        label: 'ID Front',
        control: 'file',
      },
      {
        name: 'idBack',
        label: 'ID Back',
        control: 'file',
      },
    ],
  },
  {
    title: 'Your Paying Wallet',
    subtitle: 'Submit your wallet address that you are going to send funds. DO NOT USE your exchange wallet address such as Kraken, Bitfinex, Bithumb, Binance, etc.',
    fields: [
      {
        name: 'walletAddress',
        label: 'Enter your wallet address',
        control: 'input',
        placeholder: 'Your personal wallet address',
      },
    ],
  },
];

const KycForm = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state.user);
  const { data: kycData } = useSelector((state) => state.kyc);

  const initialValues = {
    first_name: kycData?.first_name || '',
    last_name: kycData?.last_name || '',
    email: kycData?.user_email || '',
    mobile: kycData?.mobile || '',
    date_of_birth: kycData?.dateOfBirth || '',
    address: kycData?.address || '',
    city: kycData?.city || '',
    state: kycData?.state || '',
    zip_code: kycData?.zip_code || '',
  };

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(getKyCStatus())
  }, [dispatch])

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    mobile: Yup.string().required('Mobile Number is required'),
    dateOfBirth: Yup.date().required('Date of Birth is required'),
    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip_code: Yup.string().required('Zip Code is required'),
    nationality: Yup.string().required('Nationality is required'),
    idType: Yup.string().required('Type of ID is required'),
    idNumber: Yup.string().required('ID Number is required'),
    idExpiryDate: Yup.date().required('ID Expiry Date is required'),
    idFront: Yup.mixed().required('ID Front is required'),
    idBack: Yup.mixed().required('ID Back is required'),
    walletAddress: Yup.string().required('Wallet Address is required'),
  });
  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append('user', userData.pk);
    formData.append('first_name', values.first_name);
    formData.append('last_name', values.last_name);
    formData.append('mobile', values.mobile);
    formData.append('date_of_birth', moment(values.dateOfBirth).format('YYYY-MM-DD'));
    formData.append('address', values.address);
    formData.append('city', values.city);
    formData.append('state', values.state);
    formData.append('zip_code', values.zip_code);
    formData.append('nationality', values.nationality);
    formData.append('document_type', values.idType);
    formData.append('document_id', values.idNumber);
    formData.append('document_expiry_date', moment(values.idExpiryDate).format('YYYY-MM-DD'));
    formData.append('wallet_currency', values.currencyType);
    formData.append('wallet', values.walletAddress);
    formData.append('document_file_front', values.idFront);
    formData.append('document_file_back', values.idBack);
    formData.append('note', null);
    formData.append('wallet_currency', 'usdt');


    if (kycData?.verified_kyc === true || kycData?.verified_kyc === false) {
      return dispatch(kycUpdate(formData)).then(() => {
        navigate('/dashboard');
      });
    } else {
      return dispatch(handleKycApplication(formData)).then(() => {
        navigate('/dashboard');
      });;
    }
  };

  return (
    <div className='form-control'>
      <h1 className='form-control__title'>KYC Application</h1>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} className='form-control__formWrapper'>
        {formik => (
          <Form className='form-control__form' encType='multipart/form-data'>
            {sections.map((section, index) => (
              <div key={index} style={{ maxWidth: '100%' }}>
                <div className={`section-heading`}>
                  <div className={`section-heading__count`}>
                    {index < 9 ? '0' + (index + 1) : index + 1}
                  </div>
                  <div className={`section-heading__headingWrapper`}>
                    <div className={`section-heading__title`}>{section.title}</div>
                    <div className={`section-heading__subtitle`}>{section.subtitle}</div>
                  </div>
                </div>
                {section?.fields?.map((field, fieldIndex) => (
                  <FormControl
                    key={fieldIndex}
                    className='form-control__field'
                    control={field.control}
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    options={field.options}
                    icon={field.icon}
                    value={formik.values[field.name] || ''}
                  />
                ))}
              </div>
            ))}
            <div className='form-control__btnSubmitWrapper'>
              <Button className='form-control__submitBtn' type='submit' disabled={!formik.dirty || !!Object.keys(formik.errors).length} >
                Submit KYC
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default KycForm;
