import React from 'react';
import Icon from '../../Icon';
import { Field, ErrorMessage } from 'formik';
import Dropzone from 'react-dropzone';

const FileUploadField = ({ label, name, icon, ...rest }) => {
  return (
    <div className='form-control__fieldWrapper'>
      {icon && <Icon name={icon} className='form-control__icon' />}
      <label className='form-control__label' htmlFor={name}>{label}</label>
      <Field name={name}>
        {({ field, form }) => {
          const { setFieldValue } = form;
          const { value } = field;

          const handleDrop = (acceptedFiles) => {
            if (acceptedFiles && acceptedFiles.length > 0) {
              setFieldValue(name, acceptedFiles[0]);
            }
          };

          return (
            <Dropzone onDrop={handleDrop} {...rest}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className='form-control__dropzone'>
                  <input {...getInputProps()} />
                  {value ? (
                    <div>
                      <p>File: {value.name}</p>
                      <p>Size: {value.size} bytes</p>
                    </div>
                  ) : (
                    <p>Drag 'n' drop a file here, or click to select a file</p>
                  )}
                </div>
              )}
            </Dropzone>
          );
        }}
      </Field>
      <ErrorMessage
        name={name}
        component="div"
        className='form-control__error'
      />
    </div>
  );
};

export default FileUploadField;
