import React, { useMemo, useState } from "react";
import { useTable, useGlobalFilter, useSortBy } from "react-table";
import moment from "moment";
import ModalHoc from "../Modal";
import Icon from "../Icon";
// Styles & Images
import USDCIcon from "../../assets/coinIcons/usdc-icon.webp";
import USDTIcon from "../../assets/coinIcons/usdt-icon.webp";
import HYDRAIcon from "../../assets/coinIcons/hydra.webp";
import "./style.scss";

const TransactionTable = ({ transactions }) => {
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [selectedFilterStatus, setSelectedFilterStatus] = useState("Show All");
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const columns = useMemo(
    () => [
      { Header: "TRANS №", accessor: "transactionId", sortType: "basic" },
      { Header: "DATE", accessor: "date", sortType: "datetime" },
      { Header: "TOKENS", accessor: "icoAmountReserved", sortType: "basic" },
      {
        Header: "CURRENCY",
        accessor: "transactionCurrency",
        Cell: ({ value }) => (
          <div className="currency-icon">
            {value === "USDC" && (
              <img
                src={USDCIcon}
                className="transaction-table__icon"
                alt="usdc"
              />
            )}
            {value === "USDT" && (
              <img
                src={USDTIcon}
                className="transaction-table__icon"
                alt="usdt"
              />
            )}
            {value === "HYDRA" && (
              <img
                src={HYDRAIcon}
                className="transaction-table__icon"
                alt="hydra"
              />
            )}
          </div>
        ),
      },
      {
        Header: "STATUS",
        accessor: "transactionStatus",
        sortType: "basic",
        Cell: ({ value }) => (
          <span
            className={`status-cell ${
              value === "PENDING"
                ? "pending"
                : value === "REJECTED"
                ? "rejected"
                : "approved"
            }`}
          >
            {value}
          </span>
        ),
      },
    ],
    []
  );

  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal(true);
  };

  const data = useMemo(() => {
    return transactions.map((transaction) => ({
      icoAmountLeft: transaction.ico_amount_left,
      icoAmountReserved: transaction.ico_amount_reserved,
      icoAmountSold: transaction.ico_amount_sold,
      id: transaction.id,
      transactionAmountLeft: transaction.transaction_amount_left,
      transactionAmountSpent: transaction.transaction_amount_spent,
      transactionCurrency: transaction.transaction_currency.toUpperCase(),
      date: moment.utc(transaction.transaction_date).toDate(),
      transactionId: transaction.transaction_id,
      transactionStatus: transaction.transaction_status
        .split("_")[1]
        .toUpperCase(),
      user: transaction.user,
      verifiedDeposit: transaction.verified_deposit,
      verifiedFull: transaction.verified_full,
      wallet: transaction.wallet,
    }));
  }, [transactions]);

  const handleFilterToggle = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  const handleFilterStatusChange = (e) => {
    setSelectedFilterStatus(e.target.value);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    { columns, data, initialState: { sortBy: [{ id: "date", desc: true }] } },
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter } = state;

  const filter = (rows, selectedStatus, searchTerm) => {
    return rows.filter((row) => {
      const formattedStatus = row.original.transactionStatus
        .toLowerCase()
        .trim();
  
      if (
        selectedStatus === "Show All" ||
        (selectedStatus === "Pending" && formattedStatus === "pending") ||
        (selectedStatus === "Approved" && formattedStatus === "approved") ||
        (selectedStatus === "Rejected" && formattedStatus === "rejected")
      ) {
        if (searchTerm) {
          const cells = row.cells;
          for (let i = 0; i < cells.length; i++) {
            const cellContent = cells[i].value;
            if (
              cellContent &&
              cellContent
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            ) {
              return true;
            }
          }
          return false;
        } else {
          return true;
        }
      }
      return false;
    });
  };

  const filteredData = useMemo(
    () => filter(rows, selectedFilterStatus, globalFilter),
    [rows, selectedFilterStatus, globalFilter]
  );

  return (
    <div style={{ padding: "10px" }}>
      <div className="transaction-table__controls">
        <input
          type="text"
          className="transaction-table__search"
          placeholder="Search..."
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
        <div className="transaction-table__filter-container">
          <button
            className="transaction-table__filter-button"
            onClick={handleFilterToggle}
          >
            Filter
          </button>
          {showFilterDropdown && (
            <div className="transaction-table__filter-dropdown">
              <div className="transaction-table__filter-group">
                <span className="transaction-table__filter-label">
                  Transaction Status
                </span>
                <select
                  className="transaction-table__filter-select"
                  value={selectedFilterStatus}
                  onChange={handleFilterStatusChange}
                >
                  <option value="Show All">Show All</option>
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                  <option value="Rejected">Rejected</option>
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
      <table {...getTableProps()} className="transaction-table__table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="transaction-table__row"
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="transaction-table__header"
                >
                  <div className="transaction-table__header-content">
                    {column.render("Header")}
                    <span
                      className="transaction-table__sort-icon"
                      style={{ marginLeft: "0.25rem" }}
                    >
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Icon
                            name={"fa-arrow-down"}
                            className={`transaction-table__icon`}
                          />
                        ) : (
                          <Icon
                            name={"fa-arrow-up"}
                            className={`transaction-table__icon`}
                          />
                        )
                      ) : (
                        <Icon
                          name={"fa-arrows-up-down"}
                          className={`transaction-table__icon`}
                        />
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {filteredData.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="transaction-table__row"
                style={{ cursor: "pointer" }}
                onClick={() => handleRowClick(row.original)}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="transaction-table__cell"
                  >
                    {cell.column.id === "date"
                      ? moment(cell.value).format("MM, DD, YY")
                      : cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {showModal && selectedRowData && (
        <ModalHoc
          show={showModal}
          handleClose={() => setShowModal(false)}
          title="Transaction Details"
        >
          {selectedRowData && (
            <div className="transaction-details">
              <div className="transaction-details__item">
                <span className="transaction-details__label">
                  Transaction ID:
                </span>
                <span>{selectedRowData.transactionId}</span>
              </div>
              <div className="transaction-details__item">
                <span className="transaction-details__label">Date:</span>
                <span>
                  {moment.utc(selectedRowData.date).format("YYYY-MM-DD HH:mm")}
                </span>
              </div>
              <div className="transaction-details__item">
                <span className="transaction-details__label">
                  Transaction Status:
                </span>
                <span>
                  {selectedRowData.transactionStatus === "PENDING"
                    ? "Pending"
                    : selectedRowData.transactionStatus === "REJECTED"
                    ? "Rejected"
                    : "Approved"}
                </span>
              </div>
              <div className="transaction-details__item">
                <span className="transaction-details__label">
                  Transaction Currency:
                </span>
                <span>{selectedRowData.transactionCurrency}</span>
              </div>
              <div className="transaction-details__item">
                <span className="transaction-details__label">
                  Transaction Amount Spent:
                </span>
                <span>
                  {selectedRowData.transactionAmountSpent.toFixed(0)}{" "}
                  {selectedRowData.transactionCurrency}{" "}
                </span>
              </div>
              {/* <div className="transaction-details__item">
                <span className="transaction-details__label">
                  Transaction Amount Left:
                </span>
                <span>
                  {selectedRowData.transactionAmountLeft.toFixed(0)} CNDY
                </span>
              </div> */}
              <div className="transaction-details__item">
                <span className="transaction-details__label">
                  ICO Amount Sold:
                </span>
                <span>{selectedRowData.icoAmountSold.toFixed(0)} CNDY</span>
              </div>
              {/* <div className="transaction-details__item">
                <span className="transaction-details__label">
                  ICO Amount Reserved:
                </span>
                <span>{selectedRowData.icoAmountReserved.toFixed(0)} CNDY</span>
              </div> */}
              {/* <div className="transaction-details__item">
                <span className="transaction-details__label">
                  ICO Amount Left:
                </span>
                <span>{selectedRowData.icoAmountLeft.toFixed(0)} CNDY</span>
              </div> */}

              {/* <div
                className={`transaction-details__item ${
                  selectedRowData.verifyDeposit
                    ? "transaction-details__item--verified"
                    : "transaction-details__item--false"
                }`}
              >
                <span className="transaction-details__label">
                  Verified Deposit:
                </span>
                <span>
                  {selectedRowData.verifiedDeposit ? "True" : "False"}
                </span>
              </div>
              <div className="transaction-details__item">
                <span className="transaction-details__label">
                  Verified Full:
                </span>
                <span>{selectedRowData.verifiedFull ? "True" : "False"}</span>
              </div> */}
            </div>
          )}
        </ModalHoc>
      )}
    </div>
  );
};

export default TransactionTable;
