import React from "react";
import Header from "./Header";

import "./style.scss"

const Layout = ({ children, testID }) => {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="main-content-wrapper">
          {children}
        </div>
      </div>
    </div>

  )
}

export default Layout
