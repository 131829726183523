import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Spinner from "../../Spinner";
import { useSelector, useDispatch } from "react-redux";
import { handleTransactionBalance } from "../../../redux/features/transactionsSlice";
import coinsImg from "../../../assets/images/coins.webp";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import ModalHoc from "../../Modal";

const TokenBalance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    dispatch(handleTransactionBalance());
  }, [dispatch]);

  const { data, status: tokenStatus } = useSelector(
    (state) => state.transactions
  );
  const kycData = useSelector((state) => state.kyc.data);

  return (
    <div className="token-balance" data-testid="token-balance">
      {tokenStatus !== "succeeded" ? (
        <Spinner />
      ) : (
        <div className="token-balance__wrapper">
          <Row className="token-balance__content-row">
            <Col>
              <div className="token-balance__header">
                <div style={{ position: "relative" }}>
                  <img
                    src={coinsImg}
                    className="token-balance__image"
                    alt="Token Balance"
                  />
                </div>
                <div className="token-balance__body">
                  <div className="token-balance__title">Balance</div>
                  <div className="token-balance__subtitle">
                    <span>{data?.reserved || 0}</span>
                    <span>CNDY</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="token-balance__button-row">
            <Col className="token-balance__button-col">
              <button
                className="token-balance__button"
                onClick={() => {
                  kycData?.verified_kyc
                    ? navigate("/buytoken")
                    : setShowModal(true);
                }}
              >
                Buy Token
              </button>
            </Col>
          </Row>
        </div>
      )}
      {showModal && (
        <ModalHoc
          show={showModal}
          handleClose={() => setShowModal(false)}
          title="KYC application"
        >
          <div className="account-status__notSubmit">
            <h1 className="account-status__notSubmitTitle">
              You will need to submit a KYC Apllication before buying tokens.{" "}
            </h1>
            <h3 className="account-status__notSubmitSubtitle">
              Please fill in the KYC application as accurately as possible so we
              can ensure compliance on our part.
            </h3>
            <h3 className="account-status__notSubmitSubtitle">
              We will need to verify it too, before we can enable you to buy
              tokens.
            </h3>
            <div className="form-control__btnSubmitWrapper">
              <button
                className="token-balance__button"
                onClick={() => {
                  navigate("/kyc");
                }}
              >
                {kycData?.verified_kyc ? "Buy Tokens" : "KYC Application"}
              </button>
            </div>
          </div>
        </ModalHoc>
      )}
    </div>
  );
};

export default TokenBalance;
