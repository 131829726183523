import React from 'react'
import { handleLoginUser } from '../../redux/features/authSlice';
import { Button } from 'react-bootstrap'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import FormControl from './FormControl';
import './style.scss';
import { toast } from 'react-toastify';
const LoginForm = () => {

  const dispatch = useDispatch();

  const initialValues = {
    email: '',
    password: ''
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required'),
    password: Yup.string().required('Required')
  })

  const onSubmit = (values, onSubmitProps) => {
    dispatch(handleLoginUser(values))
      .unwrap()
      .then(() => {
        toast.success('Login Successful');
        onSubmitProps.resetForm();
      })
      .catch((rejectedValueOrSerializedError) => {
        toast.error(rejectedValueOrSerializedError.message);
      })
      .finally(() => {
        onSubmitProps.setSubmitting(false);
      });
  };

  return (
    <div className='form-control'>
      <h1 className='form-control__title'>Sign In</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        className='form-control__formWrapper'
      >
        {formik => (
          <Form className='form-control__form'>
            <FormControl
              className='form-control__field'
              control='input'
              type='email'
              label='Email'
              name='email'
              placeholder='Enter your email'
            />
            <FormControl
              className='form-control__field'
              control='input'
              type='password'
              label='Password'
              name='password'
              placeholder='Enter your password'
            />
            <div className='form-control__btnSubmitWrapper'>
              <Button className='form-control__submitBtn' type='submit' disabled={!formik.dirty || !!Object.keys(formik.errors).length}>
                Sign In
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default LoginForm