// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container {
  width: 100%;
  max-width: 100%;
  /* Optional: Limit the maximum width if needed */
  margin: 0 auto;
  /* Center the chart within the container */ }

.custom-tooltip {
  color: white; }

.recharts-xAxis .recharts-cartesian-axis-tick line {
  visibility: hidden; }

.recharts-xAxis text {
  visibility: hidden; }

.recharts-yAxis .recharts-cartesian-axis-tick:nth-child(1) {
  visibility: hidden; }

.recharts-yAxis .recharts-cartesian-axis-tick line:first-child {
  visibility: hidden; }
`, "",{"version":3,"sources":["webpack://./src/components/Chart/style.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,eAAe;EAAE,gDAAA;EACjB,cAAc;EAAE,0CAAA,EAA2C;;AAG/D;EACI,YAAY,EAAA;;AAGhB;EACI,kBAAkB,EAAA;;AAEtB;EACI,kBAAkB,EAAA;;AAGtB;EACI,kBAAkB,EAAA;;AAGtB;EACI,kBAAkB,EAAA","sourcesContent":[".chart-container {\n    width: 100%;\n    max-width: 100%; /* Optional: Limit the maximum width if needed */\n    margin: 0 auto; /* Center the chart within the container */\n}\n\n.custom-tooltip {\n    color: white;\n}\n\n.recharts-xAxis .recharts-cartesian-axis-tick line {\n    visibility: hidden;\n}\n.recharts-xAxis text {\n    visibility: hidden;\n}\n\n.recharts-yAxis .recharts-cartesian-axis-tick:nth-child(1) {\n    visibility: hidden;\n}\n\n.recharts-yAxis .recharts-cartesian-axis-tick line:first-child {\n    visibility: hidden;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
