import { useLayoutEffect, useEffect, useState } from "react";

export const usePreloadLCPImages = (imageUrls) => {
    useLayoutEffect(() => {
        if (imageUrls) {
            imageUrls.forEach((url) => {
                const img = new Image();
                img.src = url;
                img.decoding = "async";
                img.loading = "eager";
                img.setAttribute("fetchpriority", "high");
            });
        }
    }, [imageUrls]);
};

export const useActiveStageData = (tokenData) => {
    const [activeStageData, setActiveStageData] = useState(null);
    useEffect(() => {
        if (Array.isArray(tokenData)) {
            const activeStage = tokenData.find((stage) => stage.is_active);
            setActiveStageData(activeStage);
        }
    }, [tokenData]);
    return activeStageData;
}
