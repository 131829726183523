import React from "react";
import { useNavigate } from "react-router-dom";
// Components
import Navigation from "../../Navigation";

// Styles & Images
import logo from "../../../assets/images/logo.webp";
import "./styles.scss";

const Header = () => {
  const navigate = useNavigate();
  return (
    <header className="header">
      <div onClick={() => navigate('/dashboard')} className="header__logo-link">
        <img className="header__logo" src={logo} alt="Logo" />
      </div>
      <Navigation />
    </header>
  );
};

export default Header;
