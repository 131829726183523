// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon__menu {
  color: #2b074d; }
`, "",{"version":3,"sources":["webpack://./src/components/Icon/style.scss","webpack://./src/styles/variables/_variables.scss"],"names":[],"mappings":"AAGI;EACG,cCHuB,EAAA","sourcesContent":["@import \"../../styles/variables/variables\";\n\n.icon {\n    &__menu {\n       color: $tokenBackgroundColor\n    }\n}\n","// Main Colors\n$tokenBackgroundColor: #2b074d;\n$tokenContainerBackgroundColor: rgba($tokenBackgroundColor, 0.85);\n$tokenWhiteColor: #ffffff;\n$tokenBlackColor: #000000;\n$tokenPlaceholderColor: #757179;\n\n// Status Colors\n$tokenVerifyStatus: #60ffa8;\n$tokenPendingStatus: #ffdb81;\n$tokenErrorStatus: #ff0000;\n\n// SHADOW\n$tokenContainerShadow: 0 0 14px 0 #ff2481;\n\n// BORDER\n$tokenContainerRadius: 20px;\n$tokenContainerBorderColor: #340c5a;\n\n// BUTTON\n$tokenButtonBorderColor: #e02574;\n$tokenButtonBackgroundColor: #ff2481;\n$tokenButtonHoverColor: #e02574;\n\n// COIN BACKGROUND\n$tokenCoinBackground: #ededed;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
