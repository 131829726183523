import React from 'react';
import { LineChart, Line, XAxis, YAxis, LabelList, ResponsiveContainer, Tooltip } from 'recharts';
import './style.scss';

import active from './active.webp';
import completed from './completed.webp';
import unactive from './unactive.webp';

const Chart = ({ tokenData }) => {
  const orderMapping = {
    'One': 1,
    'Two': 2,
    'Three': 3,
    'Four': 4,
    'Five': 5,
  };

  const stagesArray = Object.values(tokenData);

  const newData = stagesArray.map((item) => {
    const stageName = item.stage.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    return {
      name: item.name,
      price: item.cndy_to_usd_price,
      stageName: stageName,
      active: item.is_active,
      completed: item.is_completed
    };
  }).sort((a, b) => {
    const stageNameA = a.stageName.split(' ')[1];
    const stageNameB = b.stageName.split(' ')[1];

    return orderMapping[stageNameA] - orderMapping[stageNameB];
  });

  newData.unshift({ name: "", price: 0 });

  const inactiveColor = "#ffffff";

  const CustomizedDot = ({ cx, cy, payload }) => {
    if (payload.price === 0) {
      return null
    }
    if (payload.active) {
      return <image x={cx - 7} y={cy - 7} width={15} height={15} xlinkHref={active} alt="active" />
    }
    if (payload.completed) {
      return <image x={cx - 7} y={cy - 7} width={15} height={15} xlinkHref={completed} alt="completed" />
    }
    else {
      return <image x={cx - 7} y={cy - 7} width={15} height={15} xlinkHref={unactive} alt="unactive" />
    }

  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <p className="label" style={{ color: 'white', }}>{`${label} : $${payload[0].value}`}</p>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer aspect={2.5}>
      <LineChart data={newData} margin={{ top: 30, right: 30, bottom: -20, left: -10 }}>
        <Line
          isAnimationActive={false}
          margin={{ top: 30, right: 30, bottom: -20, left: -10 }}
          type="basic"
          dataKey='price'
          strokeWidth={1}
          stroke='#FF2481'
          dot={<CustomizedDot />}
        >
          <LabelList
            dataKey='name'
            position='top'
            offset={15}
            fill='#ffffff'
            fontSize={12}
          />
        </Line>
        <XAxis dataKey='name' domain={[0, 'dataMax']} fontSize={10} stroke={inactiveColor} />
        <YAxis fontSize={10} type="number" stroke={inactiveColor} domain={['dataMax']} tickCount={10} />
        <Tooltip content={<CustomTooltip />} cursor={false} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
