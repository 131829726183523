import React from 'react';
import { Modal } from 'react-bootstrap';

// Styles
import './style.scss';

const ModalHoc = ({ show, handleClose, children, title }) => {
    return (
        <Modal show={show} onHide={handleClose} className="modal">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    );
};

export default ModalHoc;
